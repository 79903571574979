// filtersSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ProductCatalogueStatusType = 'all' | 'active' | 'draft';

const today = new Date();

interface FiltersState {
  productCatalogueStatusFilter: ProductCatalogueStatusType;
  productCatalogueCreatedAtStartDateFilter: Date | undefined;
  productCatalogueCreatedAtEndDateFilter: Date | undefined;
  productCatalogueCategoryFilter: string;
  productCatalogueTitleFilter: string;
  productCatalogueSkuFilter: string;
  productCatalogueVinFilter: string;
  productCatalogueHsnCodeFilter: string;
  productCatalogueFbvEnabledFilter: boolean;
}

export const initialState: FiltersState = {
  productCatalogueStatusFilter: 'all',
  productCatalogueCreatedAtStartDateFilter: undefined,
  productCatalogueCreatedAtEndDateFilter: undefined,
  productCatalogueCategoryFilter: '',
  productCatalogueTitleFilter: '',
  productCatalogueSkuFilter: '',
  productCatalogueVinFilter: '',
  productCatalogueHsnCodeFilter: '',
  productCatalogueFbvEnabledFilter: false,
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setProductCatalogueStatusFilter: (state, action: PayloadAction<ProductCatalogueStatusType>) => {
      state.productCatalogueStatusFilter = action.payload;
    },
    setProductCatalogueCreatedAtStartDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.productCatalogueCreatedAtStartDateFilter = action.payload;
    },
    setProductCatalogueCreatedAtEndDateFilter: (state, action: PayloadAction<Date | undefined>) => {
      state.productCatalogueCreatedAtEndDateFilter = action.payload;
    },
    setProductCatalogueCategoryFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueCategoryFilter = action.payload;
    },
    setProductCatalogueTitleFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueTitleFilter = action.payload;
    },
    setProductCatalogueSkuFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueSkuFilter = action.payload;
    },
    setProductCatalogueVinFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueVinFilter = action.payload;
    },
    setProductCatalogueHsnCodeFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueHsnCodeFilter = action.payload;
    },
    setProductCatalogueFbvEnabledFilter: (state, action: PayloadAction<boolean>) => {
      state.productCatalogueFbvEnabledFilter = action.payload;
    },
    resetFilter: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setProductCatalogueStatusFilter,
  setProductCatalogueCreatedAtStartDateFilter,
  setProductCatalogueCreatedAtEndDateFilter,
  setProductCatalogueCategoryFilter,
  setProductCatalogueTitleFilter,
  setProductCatalogueSkuFilter,
  setProductCatalogueVinFilter,
  setProductCatalogueHsnCodeFilter,
  setProductCatalogueFbvEnabledFilter,
  resetFilter
} = filtersSlice.actions;
export default filtersSlice.reducer;
