import { Box, useMediaQuery, useTheme } from "@mui/material";
import styles from "./Navigation.module.scss";
import SideBar from "../../molecules/Sidebar/SideBar";

type NavigationPropType = {
  children: JSX.Element;
}


const Navigation: React.FC<NavigationPropType> = ({children: outlet}) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className={styles.navWrapper}>
      <Box className={styles.sideBarSection}>
        <SideBar />
      </Box>
      <Box className={styles.layoutSection}>
        {outlet}
      </Box>
    </Box>
  )
}

export default Navigation;