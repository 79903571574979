import Box from '@mui/material/Box';
import { List, ListItem, ListItemText} from '@mui/material';
import { Link } from 'react-router-dom';
import './SideBar.scss';
import { useState } from 'react';
import {ROUTES} from "../../../constants";
import { useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import LogoutModal from '../../../components/atoms/LogoutModal/LogoutModal';
import { useDispatch } from 'react-redux';
import { resetAuth } from '../../../slices/authSlice';

const SideBar: React.FC = () => {

  const location = useLocation();

  const isSelected = (route: string) => {
    return location.pathname === route ? 'selected' : '';
  }

  const [showLogoutPopup, setShowLogoutPopup] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(resetAuth());
  }

  return (
    <Box  className="nav-sidebar-wrapper">
      <List className="sidebar-link-list">
        <ListItem className={`sidebar-link ${isSelected(ROUTES.PRODUCTS)}`} component={Link} to={ROUTES.PRODUCTS} >
          <ListItemText primary="Products" className='link-text' />
        </ListItem>
        <div className={`bottom-btn-wrapper`}>
          <ListItem className={`sidebar-link cursor-pointer`} onClick={() => {setShowLogoutPopup(true)}} >
            <ListItemText primary="Logout" className='link-text' />
            <LogoutIcon fontSize='small' />
          </ListItem>
        </div>
      </List>
      <LogoutModal
        open={showLogoutPopup}
        onClose={() => setShowLogoutPopup(false)}
        onLogout={handleLogout}
      />
    </Box>
  );
}

export default SideBar;