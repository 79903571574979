import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from '@mui/material/Button';

interface ColorButtonPropType extends ButtonProps{
  bgColor : string;
  bgHoverColor?: string;
}

const ColorButton = styled(Button)<ColorButtonPropType>(({ theme, bgColor, bgHoverColor}) => ({
  color: theme.palette.getContrastText(bgColor),
  backgroundColor: bgColor,
  textTransform: 'none',
  fontWeight: 600,
  '&:hover': {
    backgroundColor: bgHoverColor || bgColor,
  },
}));

export default ColorButton;