import { Box, SwipeableDrawer, useMediaQuery, useTheme } from "@mui/material";
import styles from "./DateRangeSelector.module.scss";
import ModalBox from "../../../components/atoms/ModalBox/ModalBox";
import { DateRangePicker } from "react-date-range";
import { useEffect, useState } from "react";
import { disableFutureDates } from "../../../helpers/utils";
import ColorButton from "../../../components/atoms/ColorButton/ColorButton";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

type DateType = {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

type PropType = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  disabledDates?: (date: Date) => boolean;
  updateDateRange: (dateRange: DateType) => void;
  header?: React.ReactNode;
  allowClear?: boolean;
} & DateType;

const DateRangeSelector:React.FC<PropType> = ({startDate, endDate, open, onClose, disabledDates = disableFutureDates, updateDateRange, header = null, allowClear = false, onOpen}) => {

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentDates, setCurrentDates] = useState<DateType>({
    startDate: undefined,
    endDate: undefined
  });

  useEffect(() => {
    if(startDate === undefined || endDate === undefined){
      setCurrentDates({
        startDate: new Date(new Date().setDate(new Date().getDate() - 15)),
        endDate: new Date()
      })
    }else{
      setCurrentDates({startDate, endDate});
    }
  }, [startDate, endDate]);


  const onSubmit = () => {
    onClose();
    updateDateRange({
      startDate: currentDates.startDate,
      endDate: currentDates.endDate,
    });
  }

  const onClear = () => {
    onClose();
    updateDateRange({
      startDate: undefined,
      endDate: undefined,
    });
  }

  const handleDateRangeChange = (ranges: any) => {
    setCurrentDates({
      startDate: ranges.selection.startDate as (Date | undefined),
      endDate: ranges.selection.endDate as (Date | undefined)
    });
  }

  const handleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }
    open ? onOpen() : onClose();
  };

  // TODO: Write a wrapper

  if(isMobile){
    return (
      <SwipeableDrawer
          anchor={"bottom"}
          open={open}
          onClose={handleDrawer(false)}
          onOpen={handleDrawer(true)}
      >
        <div className={isMobile ? styles['swipeable-mobile-drawer']: undefined}>
          <div className={styles['picker-header']}>
            {header}
          </div>
          <DateRangePicker
            ranges={[{startDate: currentDates.startDate, endDate: currentDates.endDate, key: 'selection'}]}
            onChange={handleDateRangeChange}
            moveRangeOnFirstSelection={false}
            showDateDisplay={false}
            showMonthAndYearPickers={false}
            showPreview={false}
            disabledDay={disabledDates}
            inputRanges={[]}
            classNames={isMobile ? {
              dateRangePickerWrapper: styles.dateRangePickerWrapper,
            }: {}}
          />
          <div className={styles['picker-footer']}>
            {allowClear && (
              <ColorButton bgColor="#FFF" className={styles['clear-button']} variant="outlined" onClick={onClear}>
                Clear
              </ColorButton>
            )}
            <ColorButton bgColor="#3361FF" bgHoverColor="#2E58E8" className={styles['submit-button']} onClick={onSubmit}>
              Apply
            </ColorButton>
          </div>
        </div>
      </SwipeableDrawer>
    );
  }

  return (
    <ModalBox
      open={open}
      onClose={onClose}
      className={styles['picker-modal']}
    >
    <div className={isMobile ? styles['swipeable-mobile-drawer']: undefined}>
        <div className={styles['picker-header']}>
          {header}
        </div>
        <DateRangePicker
          ranges={[{startDate: currentDates.startDate, endDate: currentDates.endDate, key: 'selection'}]}
          onChange={handleDateRangeChange}
          moveRangeOnFirstSelection={false}
          showDateDisplay={false}
          showMonthAndYearPickers={false}
          showPreview={false}
          disabledDay={disabledDates}
          inputRanges={[]}
          classNames={isMobile ? {
            dateRangePickerWrapper: styles.dateRangePickerWrapper,
          }: {}}
        />
        <div className={styles['picker-footer']}>
          {allowClear && (
            <ColorButton bgColor="#FFF" className={styles['clear-button']} variant="outlined" onClick={onClear}>
              Clear
            </ColorButton>
          )}
          <ColorButton bgColor="#3361FF" bgHoverColor="#2E58E8" className={styles['submit-button']} onClick={onSubmit}>
            Apply
          </ColorButton>
        </div>
      </div>
    </ModalBox>
  );
}

export default DateRangeSelector;

type DateRangeLabelPropType = DateType & {
  onClick: () => void;
  of?: string;
  noDateLabel?: string;
}

export const DateRangeLabel:React.FC<DateRangeLabelPropType> = ({startDate, endDate, onClick, of = '', noDateLabel}) => {
  const label = startDate && endDate
    ? `${startDate.getDate()} ${startDate.toLocaleString('default', { month: 'short' })} - ${endDate.getDate()} ${endDate.toLocaleString('default', { month: 'short' })}`
    : (noDateLabel ? noDateLabel: 'NA - NA');
  return (
    <Box className={styles.dateRangeLabel} onClick={onClick}>
      <CalendarTodayIcon className={styles.icon} />
      <Box>
        <p>{label}</p>
        {of && <p>{of}</p>}
      </Box>
    </Box>
  );
}