import { Outlet } from "react-router-dom";
import {  useAppSelector } from "../hooks/redux-hooks";
import { Navigate } from "react-router-dom";
import Navigation from "../components/organisms/Navigation/Navigation";
import Header from "../components/atoms/Header/Header";

const ProtectedLayout = () => {
  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);

  if (!basicUserInfo) {
    return <Navigate replace to={"/login"} />;
  }

  return (
    <>
      <Header />
      <Navigation>
        <Outlet />
      </Navigation>
    </>
  );
};

export default ProtectedLayout;
