import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import resetPasswordSlice from "./slices/resetPasswordSlice";
import forgotPasswordSlice from "./slices/forgotPasswordSlice";
import notificationReducer from "./slices/notificationSlice";
import userReducer from "./slices/userSlice";
import filtersSliceReducer from "./slices/filtersSlice";
import productCatalogueReducer from "./slices/productCatalogueSlice";
import { axiosMiddleware } from "./api/middleware";

const store = configureStore({
  reducer: {
    auth: authReducer,
    resetPassword: resetPasswordSlice,
    forgotPassword: forgotPasswordSlice,
    notification: notificationReducer,
    user: userReducer,
    productCatalogue: productCatalogueReducer,
    filters: filtersSliceReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(axiosMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
