import { Box, ClickAwayListener, useMediaQuery, useTheme } from "@mui/material";
import styles from "./Header.module.scss";
import SolomonDesktopIcon from "../../../images/solomon_desktop.png";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import LogoutModal from "../LogoutModal/LogoutModal";

import { useState } from "react";
import LightTooltip from "../LightTooltip/LightTooltip";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { useDispatch } from "react-redux";
import { resetAuth } from "../../../slices/authSlice";

const Header = () => {

  const theme = useTheme();

  const dispatch = useDispatch();

  const [showUserOp, setShowUserOp] = useState<boolean>(false);

  const [showLogoutPopup, setShowLogoutPopup] = useState<boolean>(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogout = () => {
    dispatch(resetAuth());
  }

  const user_logo = useAppSelector((state) => state.auth.basicUserInfo?.logo_url) || null;

  return (
    <>
      <Box className={styles.wrapper} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <div className={styles.brandInfo}>
          <img className={styles.forgeIcon} src={SolomonDesktopIcon} alt={'Solomon Icon'} />
        </div>
        <div className={styles.userInfo}>
          {user_logo && <img src={user_logo} className={styles.supplierLogo} alt={'Supplier Logo'} /> }
          <ClickAwayListener onClickAway={() => setShowUserOp(false)}>
            <div>
              <LightTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                placement="bottom-end"
                onClose={() => {setShowUserOp(false)}}
                open={showUserOp}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={<p className={styles.logoutButton} onClick={() => {
                  setShowLogoutPopup(true);
                  setShowUserOp(false);
                }}>Logout</p>}
              >
                <div className={styles.userIcon} onClick={() => setShowUserOp((prevVal) => !prevVal)}>
                  <PersonOutlineOutlinedIcon fontSize="small" />
                  {showUserOp ? <ExpandLessOutlinedIcon fontSize="small" /> : <ExpandMoreOutlinedIcon fontSize="small" />}
                </div>
              </LightTooltip>
            </div>
          </ClickAwayListener>
        </div>
      </Box>
      <LogoutModal
        open={showLogoutPopup}
        onClose={() => setShowLogoutPopup(false)}
        onLogout={handleLogout}
      />
    </>
  )
}

export default Header;