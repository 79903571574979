export const BACKEND_BASE_URL = "https://api.vaaree.com";

export const ROUTES = {
  PRODUCTS: "/",
}


export type DateRangeType = {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

export type MonthYearType = {
  month: number | undefined;
  year: number | undefined;
}