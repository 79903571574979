import broken_img from "../../../images/broken_img.png";

const Img:React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = (props) => {

  const handleErr = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    console.log(`"Error loading image: ${e.currentTarget.src}`);
    e.currentTarget.src = broken_img;
  }
  
  return (
    <img {...props} src={props.src || broken_img} onError={handleErr} />
  );
}

export default Img;