import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import {ColumnPropType} from "../../components/organisms/TableManager/TableManager"
import styles from "./ProductCatalogue.module.scss";
import { ProductDataHash } from "../../slices/productCatalogueSlice";
import { checkCurrencyNA, checkNA, checkPercentNA, csx } from "../../helpers/utils";
import FbvIcon from "../../images/fbv-icon.png";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Img from "../../components/atoms/Img/Img";

type LabelValueBlockPropType = {
  label: string;
  value: string | null | number | undefined;
  statusLabel?: boolean;
}

const LabelValueBlock:React.FC<LabelValueBlockPropType> = (props) => {
  return (
    <p className={styles.label}>
      {`${props.label}:`} <span className={csx(styles.value, props.statusLabel ? styles.statusLabel: null)}>{checkNA(props.value)}</span>
    </p>
  );
}


type CardColumnPropType = {
  data: LabelValueBlockPropType[];
  className?: string;
};

const CardColumn:React.FC<CardColumnPropType> = (props) => {
  const {data, className = undefined} = props;
  return (
    <Box className={csx(styles.cardColumn, className)}>
      {data.map((item, idx) => {
        return <LabelValueBlock 
          key={idx}
          label={item.label}
          value={item.value}
          statusLabel={item.statusLabel}
        />
      })}
    </Box>
  );
}

const CardView:React.FC<ProductDataHash> = (props) => {
  const {live, image_src, title, sku, fbv_enabled, variant_title, created_at, updated_at, category, sub_category, vin, price, compare_at_price, inventory_quantity, status, } = props;
  return (
    <Box className={styles.productCard}>
      <Box className={styles.thumbnailSection}>
        <Img className={styles.thumbnail} src={image_src || undefined} alt={title || 'Product Default Title'} />
        <Box className={styles.thumbnailData}>
          <p className={styles.skuCode}>
            {checkNA(sku)} {fbv_enabled && <img className={styles.fbvIcon} src={FbvIcon} alt="FBV Icon"/>}
          </p>
          <p className={styles.label}>{checkNA(title)}</p>
          <LabelValueBlock label="Variant" value={variant_title} />
          <LabelValueBlock label="Product Added" value={created_at} />
        </Box>
      </Box>
      <CardColumn
        data={[
          {label: 'Category', value: category},
          {label: 'Sub-Category', value: sub_category},
          {label: 'VIN', value: vin}
        ]}
      />
      <CardColumn
        data={[
          {label: 'Listing Price', value: checkCurrencyNA(price)},
          {label: 'Maximum Retail Price', value: checkCurrencyNA(compare_at_price)},
        ]}
      />
      <CardColumn
        data={[
          {label: 'Status', value: status, statusLabel: live},
          {label: 'Stock on Hand', value: inventory_quantity},
        ]}
      />
    </Box>
  );
}

type CardGridPropType = {
  data: LabelValueBlockPropType[];
  className?: string;
};

const CardGrid:React.FC<CardGridPropType> = (props) => {
  const {data, className = undefined} = props;
  return (
    <Box className={csx(styles.cardGrid, className)}>
      {data.map((item) => {
        return (
          <>
            <p className={styles.label}>{`${item.label}`}</p>
            <p className={csx(styles.value, item.statusLabel ? styles.statusLabel: null)}>{checkNA(item.value)}</p>
          </>
        )
      })}
    </Box>
  );
}

const ExpandCardView:React.FC<ProductDataHash> = (props) => {
  const {live, id, image_src, sku, fbv_enabled, title, variant_title, created_at, status, category, sub_category, vin, inventory_quantity, price, compare_at_price} = props;
  return (
    <Box className={styles.expandProductCard} key={`${id}`}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="small" />} >
          <Box className={csx(styles.thumbnailSection, styles.mobile)}>
            <Img className={styles.thumbnail} src={image_src || undefined} alt={title || 'Product Default Title'} />
            <Box className={styles.thumbnailData}>
              <p className={styles.skuCode}>
                {checkNA(sku)} {fbv_enabled && <img className={styles.fbvIcon} src={FbvIcon} alt="FBV Icon"/>}
              </p>
              <p className={styles.label}>{checkNA(title)}</p>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <CardGrid 
            data={[
              {label: 'Status', value: status, statusLabel: live},
              {label: 'Variant', value: variant_title},
              {label: 'Product Added', value: created_at},
              {label: 'Category', value: category},
              {label: 'Sub-Category', value: sub_category},
              {label: 'VIN', value: vin},
              {label: 'Listing Price', value: checkCurrencyNA(price)},
              {label: 'Maximum Retail Price', value: checkCurrencyNA(compare_at_price)},
              {label: 'Stock on Hand', value: inventory_quantity},
            ]}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export const productCatalogueColumns = (isMobile: boolean): ColumnPropType<ProductDataHash>[]  =>  [
  {
    label: 'Product ID',
    accessor: 'id',
    minWidth: isMobile ? undefined : 1200,
    cell: ({row}) => isMobile ? <ExpandCardView {...row} /> : <CardView {...row} />
  }
]